<script setup lang="ts">
  import { useSnackBarStore } from "~/stores/SnackBarStore";
  import { useErrorStore } from "~/stores/ErrorStore";
  import { useGTM } from "~/composables/useGTM";

  // スナックバーstore
  const snackBarStore = useSnackBarStore();

  // エラー画面
  const errorStore = useErrorStore();
  errorStore.reset();

  // GTM
  const gtm = useGTM();

  onMounted(() => {
    gtm.onMounted();
  });

  onUnmounted(() => {
    gtm.onUnmounted();
  });
</script>

<template>
  <v-app>
    <slot></slot>
    <MoleculesErrorDialog
      v-model:is-open="errorStore.isOpen"
      :message="errorStore.dialogMessage"
      :button-title="errorStore.buttonTitle"
      @click-close="errorStore.close"
    />

    <AtomsAlertSnackBar
      v-model:show="snackBarStore.show"
      :color="snackBarStore.color"
      :timeout="snackBarStore.timeOutMilliSecond"
    >
      {{ snackBarStore.message }}
    </AtomsAlertSnackBar>
  </v-app>
</template>
